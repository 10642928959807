/** @jsxImportSource @emotion/react */
import { createTheme, ThemeProvider } from '@mui/material';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/constants.ts';
import { FONTS } from '../../common/constants.ts';

const BLUE = {
  light: COLORS.BLUE,
  dark: COLORS.BLUE,
  main: COLORS.BLUE,
  contrastText: '#FFFFFF',
};

const RED = {
  light: COLORS.ERROR_RED,
  dark: COLORS.ERROR_RED,
  main: COLORS.ERROR_RED,
};

const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: color.format(-0.45),
        },
      },
    },
  },
  palette: {
    primary: BLUE,
    secondary: BLUE,
    text: {
      disabled: '#888888',
    },
    common: {
      white: '#FFFFFF',
      black: color.format(-0.6),
    },
    error: RED,
  },
  typography: {
    fontFamily: FONTS.Roboto.family,
    allVariants: {
      color: color.format(-0.8),
    },
  },
});

export const Theme = ({ children }: { children: React.ReactNode }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);
