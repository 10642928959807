import chroma from 'chroma-js';
import * as R from 'ramda';

export const RED = 'rgba(255, 0, 0, 0.1)';

/**
 * Creates a new chroma instance
 * https://gka.github.io/chroma.js/.
 *
 * @example create('white') // White
 * @example create('#ffffff') // White
 * @example create(0xffffff) // White
 * @example create([255, 255, 255]) // White
 */
export const create = (value: string | number): chroma.Color => chroma(value);

/**
 * Takes a value of various types and converts it into a color.
 *
 * @example format(-1)  // rgba(0, 0, 0, 1) (black)
 * @example format(0.5) // rgba(255, 255, 255, 0.5) (translucent white)
 */
export function format(value: string | number): string | undefined {
  if (!value) return;

  return R.is(Number, value) ? toGrayAlpha(value) : value;
}

/**
 * A number between -1 (black) and 1 (white).
 */
const toGrayAlpha = (value: number): string => {
  if (value < -1) {
    value = -1;
  }
  if (value > 1) {
    value = 1;
  }

  // Black.
  if (value < 0) {
    return chroma('black').alpha(Math.abs(value)).hex();
  }

  // White.
  if (value > 0) {
    return chroma('white').alpha(value).hex();
  }

  return 'rgba(0, 0, 0, 0.0)'; // Transparent.
};
